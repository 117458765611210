<template>
  <div>
    <index path="/datareport/annualReport"></index>
    <div style="position: absolute; right: 1%; width: 81%; top: 1%">
      <div>
        <div
          class="tools-bar"
          style="
            float: right;
            padding-top: 1vh;
            padding-bottom: 1vh;
            font-size: 1.6vh;
          "
        >
          <el-select
            v-model="supplierId"
            placeholder="服务商"
            size="mini"
            class="selectClass"
            @change="selectSupplier"
          >
            <el-option
              v-for="item in providers"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="orgin"
            placeholder="终端用户"
            size="mini"
            class="selectClass"
          >
            <el-option
              v-for="item in orginList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="annual"
            placeholder="年度"
            size="mini"
            class="selectClass"
            @change="selecAnnual"
          >
            <el-option
              v-for="item in annualList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-select
            v-if="annual == '年度'"
            v-model="year"
            placeholder="年份"
            size="mini"
            class="selectClass"
          >
            <el-option
              v-for="item in yearAndMonthList"
              :key="item.years"
              :label="item.years"
              :value="item.years"
            >
            </el-option>
          </el-select>
          <el-select
            v-else
            v-model="year"
            placeholder="季度"
            size="mini"
            class="selectClass"
          >
            <el-option
              v-for="item in quarterList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-button
            style="margin-left: 0.7vw"
            size="mini"
            type="primary"
            icon="el-icon-document"
            @click="generateReport"
            >生成报告</el-button
          >
          <el-button
            style="margin-left: 0.7vw"
            size="mini"
            type="primary"
            icon="el-icon-download"
            @click="printOut"
            >下载报告</el-button
          >
        </div>
      </div>
    </div>
    <div
      id="renderPdf"
      class="annual-container"
    >
      <div style="height:1.5vh;">
      </div>
      <div
        class="annualClass"
      >
        <div class="annual-border">
          <img
            src="~@/assets/images/logo.png"
            class="annual-img"
          />
          <section>
          
            <article>
              <h2 style="text-align: center">{{reportName}}</h2>
            </article>
          </section>
        </div>
        <div :style="showAlertInfo ? 'border-bottom: solid 2px #a5a5a5;' : ''">
          <div style="padding-left: 7vw; padding-top: 30px">
            <div>客户名称：{{libInfos.orginName}}</div>
            <div style="padding-top:15px;">库房：{{libInfos.libName}}</div>
            <div style="padding-top:15px;">时间：{{libInfos.year}}</div>
          </div>
          <div class="annual-library">
            <span class="annual-count">烟雾感应报警终端：{{countList.firemodelcount}}个</span>
            <span class="annual-count">烟感报警：{{countList.firealarmcount}}次</span>
          </div>
          <div class="annual-library">
            <span class="annual-count">水浸感应终端数量：{{countList.watermodelcount}}个</span>
            <span >水浸报警：{{countList.wateralarmcount}}次</span>
          </div>
          <div class="annual-library">
            <span class="annual-count">温湿度感应终端：{{countList.tempandhumitymodelcount}}个</span>
            <span class="annual-count">温度报警：{{countList.tempalarmcount}}次</span>
            <span class="annual-count">湿度报警：{{countList.humidityalarmcount}}次</span>
          </div>
          <div style="display: flex;padding-top: 2.5vh;text-align: center;ont-size: 1.6vh;">
            <div style="width:24vw">烟感报警时长占比</div>
            <div style="width:24vw">浸水报警时长占比</div>
            <div style="width:24vw">温度报警时长占比</div>
            <div style="width:24vw">湿度报警时长占比</div>
          </div>
          <div style="display: flex; height: 24vh">
            <div
              id="fireCharts"
              class="annual-pie"
            ></div>
            <div
              id="waterCharts"
              class="annual-pie"
            ></div>
            <div
              id="tempCharts"
              class="annual-pie"
            ></div>
            <div
              id="humidityCharts"
              class="annual-pie"
            ></div>
          </div>
          <div style="padding-left: 5vw;font-size: 1.5vh;margin-bottom: 1vh;">注：报警时长占比数值代表警情存在时长比设备使用时长，即数值越大代表警情存在时间越长 </div>
          <div>
            <div id="alarmLineChart" style="height: 35vh"></div>
          </div>
        </div>
        <div :style="showAlertInfo ? '' : 'display:none;'">
          <section>
            <article id="textNode" style="padding:0.5vw 0.5vw 0.5vw 3vw;">
            </article>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import index from "@/views/datareport/index";
import { getProviders, getOrginListBySupplierId, getReportEnvironmentData,getYearList } from "@/api/datareport";
import echarts from "echarts";
import html2Canvas from "html2canvas";
import JsPDF from "jspdf";

export default {
  components: {
    index,
  },
  data() {
    return {
      providers: [],
      supplierId: null,
      orgin: null,
      orginList: [],
      annual: "年度",
      annualList: [
        { id: 1, name: "年度" },
        { id: 2, name: "季度" },
      ],
      countList:{},
      durationList:{
        firesum:0,
        humiditysum:0,
        tempsum:0,
        watersum:0
      },
      reportName:"库房环境监测数据年度报告",
      yearAndMonthList:[],//年度列表
      year:null,//选择的年份或季度
      series:null,
      width:0,
      height:0,
      quarterList:[],//季度列表
      xData:[],
      seriesData:[],
      libInfos:{
        orginName:null,
        libName:null,
        year:null
      },
      showAlertInfo:false,//是否显示评估风险信息
    };
  },
  mounted() {
    this.getProviders();
    
  },
  methods: {
    getProviders() {
      getProviders().then((res) => {
        if (res.data.state == "ok") {
          this.providers = res.data.data;
          this.supplierId = res.data.data[0].id;
          this.getOrginList();
          this.getYearList()
        }
      });
    },
    getOrginList() {
      let params = {
        supplierId: this.supplierId,
      };
      getOrginListBySupplierId(params).then((res) => {
        if (res.data.state == "ok") {
          this.orginList = res.data.data;
          this.orgin = this.orginList[0].id
          this.libInfos.orginName = this.orginList[0].name
        }
      });
    },
    getYearList(){
      getYearList({annual:this.annual}).then((res) => {
        if (res.data.state == "ok") {
          this.yearAndMonthList = res.data.data
          this.year = res.data.data[res.data.data.length-1].years
          this.yearAndMonthList.forEach(element => {
            for (let index = 0; index < 4; index++) {
              if (index == 0) {
                this.quarterList.push(element.years + '-第一季度')
              } else if (index ==1) {
                this.quarterList.push(element.years + '-第二季度')
              } else if (index ==2) {
                this.quarterList.push(element.years + '-第三季度')
              } else if (index ==3) {
                this.quarterList.push(element.years + '-第四季度')
              }
            }
          });
          this.initData()
        }
      });
    },
    selectSupplier() {
      this.getOrginList();
    },
    selecAnnual(){
      this.year = null
    },
    initData(){
      this.generateReport()
    },
    /**
     * 生成报告
     */
    generateReport() {
      if(this.year == null) {
        this.$message.warning("请选择年份或季度")
        return
      }
      let params = {
        supplierId: this.supplierId,
        orginId: this.orgin,
        year:this.year
      };
      getReportEnvironmentData(params).then((res) => {
        if (res.data.state == "ok") {
          this.countList = res.data.data.count
          this.durationList = res.data.data.duration
          this.orginList.forEach(element => {
            let id = element.id
            if (id === this.orgin) {
              this.libInfos.orginName = element.name
            }
          });
          if (this.durationList.firesum ==0) {
            this.durationList.firesum = this.durationList.firesum.toPrecision(1)
          } else if (this.durationList.firesum > 0 && this.durationList.firesum < 0.1) {
            this.durationList.firesum = (this.durationList.firesum*100).toPrecision(1)
          } else if (this.durationList.firesum >= 0.1 && this.durationList.firesum < 1) {
            this.durationList.firesum = (this.durationList.firesum*100).toPrecision(2)
          } else if (this.durationList.firesum >= 1 && this.durationList.firesum < 10) {
            this.durationList.firesum = (this.durationList.firesum*100).toPrecision(3)
          } else {
            this.durationList.firesum = (this.durationList.firesum*100).toPrecision(4)
          }

          if (this.durationList.watersum ==0) {
            this.durationList.watersum = this.durationList.watersum.toPrecision(1)
          } else if (this.durationList.watersum > 0 && this.durationList.watersum < 0.1) {
            this.durationList.watersum = (this.durationList.watersum*100).toPrecision(1)
          } else if (this.durationList.watersum >= 0.1 && this.durationList.watersum < 1) {
            this.durationList.watersum = (this.durationList.watersum*100).toPrecision(2)
          } else if (this.durationList.watersum >= 1 && this.durationList.watersum < 10) {
            this.durationList.watersum = (this.durationList.watersum*100).toPrecision(3)
          } else {
            this.durationList.watersum = (this.durationList.watersum*100).toPrecision(4)
          }

          if (this.durationList.tempsum ==0) {
            this.durationList.tempsum = this.durationList.tempsum.toPrecision(1)
          } else if (this.durationList.tempsum > 0 && this.durationList.tempsum < 0.1) {
            this.durationList.tempsum = (this.durationList.tempsum*100).toPrecision(1)
          } else if (this.durationList.tempsum >= 0.1 && this.durationList.tempsum < 1) {
            this.durationList.tempsum = (this.durationList.tempsum*100).toPrecision(2)
          } else if (this.durationList.tempsum >= 1 && this.durationList.tempsum < 10) {
            this.durationList.tempsum = (this.durationList.tempsum*100).toPrecision(3)
          } else {
            this.durationList.tempsum = (this.durationList.tempsum*100).toPrecision(4)
          }

          if (this.durationList.humiditysum ==0) {
            this.durationList.humiditysum = this.durationList.humiditysum.toPrecision(1)
          } else if (this.durationList.humiditysum > 0 && this.durationList.humiditysum < 0.1) {
            this.durationList.humiditysum = (this.durationList.humiditysum*100).toPrecision(1)
          } else if (this.durationList.humiditysum >= 0.1 && this.durationList.humiditysum < 1) {
            this.durationList.humiditysum = (this.durationList.humiditysum*100).toPrecision(2)
          } else if (this.durationList.humiditysum >= 1 && this.durationList.humiditysum < 10) {
            this.durationList.humiditysum = (this.durationList.humiditysum*100).toPrecision(3)
          } else {
            this.durationList.humiditysum = (this.durationList.humiditysum*100).toPrecision(4)
          }
          
          this.series = res.data.data.series
          this.libInfos.libName = res.data.data.count.libs
          if (this.year.indexOf("-") > 0) {
            this.libInfos.year = this.year.slice(0,4) + '年' + this.year.slice(4)
          } else {
            this.libInfos.year = this.year + '年'
          }
          if (this.annual == '季度') {
            this.reportName = '库房环境监测数据季度报告'
          }
          var pDom = document.getElementsByTagName('p')
          if (pDom.length > 0) {
            for (let index = pDom.length - 1;index >=0; index--) {
              var p =document.getElementsByTagName('p')[index];
              p.parentNode.removeChild(p);
            }
          }
          let fireState = false,waterState = false,tempState = false,humidityState = false;
          for (let index = 1; index < 5; index++) {
            if (this.countList.firealarmcount > 0 && !fireState) {
              var para = document.createElement("p")
              var node = document.createTextNode(index + "、存在火灾隐患，建议配备七氟丙烷自动灭火器。")
              para.appendChild(node);
              var element=document.getElementById("textNode"); 
              element.appendChild(para);
              fireState = true
            } else if (this.countList.wateralarmcount > 0 && !waterState) {
              var para = document.createElement("p")
              var node = document.createTextNode(index + "、存在漏水风险，建议升级设备为漏水绳，多点监测，减少监测盲区。")
              para.appendChild(node);
              var element=document.getElementById("textNode"); 
              element.appendChild(para);
              waterState = true
            } else if (this.countList.tempalarmcount > 0 && !tempState) {
              var para = document.createElement("p")
              var node = document.createTextNode(index + "、温度频繁报警，存储环境不满足国家档案局发布的《档案馆建筑设计规范JGJ25-2021》中“5.2.温湿度”要求，建议加装空调。")
              para.appendChild(node);
              var element=document.getElementById("textNode"); 
              element.appendChild(para);
              tempState = true
            }  else if (this.countList.humidityalarmcount > 0 && !humidityState) {
              var para = document.createElement("p")
              var node = document.createTextNode(index + "、湿度频繁报警，存储环境不满足国家档案局发布的《档案馆建筑设计规范JGJ25-2021》中“5.2.温湿度”要求，建议加装恒湿机。")
              para.appendChild(node);
              var element=document.getElementById("textNode"); 
              element.appendChild(para);
              humidityState = true
            }
            if (fireState || waterState || humidityState || tempState) {
              this.showAlertInfo = true
            } else {
              this.showAlertInfo = false
            }
          }
          this.seriesData = []
          this.xData = []
          this.freshLineData(res.data.data.series)
          this.initFireChart();
          this.initwaterChart();
          this.initHumidityChart();
          this.initTempChart();
          this.initAlarmLineChart();
        }
      });
    },
    //下载报告
    printOut(){
      var domName = document.getElementById("renderPdf")
      window.pageYoffset = 0; // 滚动置顶
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      var that = this
      var shareContent = domName;
      //打印看有没有获取到dom
      var width = shareContent.offsetWidth; //获取dom 宽度
      var height = shareContent.offsetHeight; //获取dom 高度
      var canvas = document.createElement("canvas"); //创建一个canvas节点
      var scale = 2; //定义任意放大倍数 支持小数
      canvas.width = width * scale; //定义canvas 宽度 * 缩放，在此我是把canvas放大了2倍
      canvas.height = height * scale; //定义canvas高度 *缩放
      canvas.getContext("2d").scale(scale, scale); //获取context,设置scale
      html2Canvas(domName, {
        //允许跨域图片的加载
        useCORS: true,
        dpi: window.devicePixelRatio , //将分辨率提高到特定的DPI 提高四倍
        // scale: 2, //按比例增加分辨率
      }).then(function(canvas) {
        var context = canvas.getContext("2d");
        //关闭抗锯齿
        context.mozImageSmoothingEnabled = false;
        context.webkitImageSmoothingEnabled = false;
        context.msImageSmoothingEnabled = false;
        context.imageSmoothingEnabled = false;
        var imgData = canvas.toDataURL("image/", 1.0); //转化成base64格式
        var img = new Image();
        img.src = imgData;
        img.onload = function() {
          img.width = img.width / 2; //因为在上面放大了2倍，生成image之后要/2
          img.height = img.height / 2;
          img.style.transform = "scale(0.5)";
          if (this.width > this.height) {
            //此可以根据打印的大小进行自动调节
            var doc = new JsPDF("l", "mm", [
              this.width * 0.555,
              this.height * 0.555
            ]);
          } else {
            var doc = new JsPDF("p", "mm", [
              this.width * 0.555,
              this.height * 0.555
            ]);
          }
          
          doc.addImage(
            imgData,
            "jpeg",
            10,
            0,
            this.width * 0.505,
            this.height * 0.545
          );
          doc.save(that.reportName + ".pdf");
        };
      });
    },
    freshLineData(series){
      let fireCounts=[],waterCounts=[],tempCounts=[],humidityCounts=[]
      let quarter = ''
      if (this.year.indexOf("季度") !== -1) {
        quarter = this.year.substring(5)
      } else {
        quarter = this.year
      }
        let months = []
        switch (quarter) { 
          case "第一季度":
            months.push(1,2,3)
            break;
          case "第二季度":
            months.push(4,5,6)
            break;
          case "第三季度":
            months.push(7,8,9)
            break;
          case "第三季度":
            months.push(10,11,12)
            break;
          default:
            months.push(1,2,3,4,5,6,7,8,9,10,11,12)
            break;
        }

        for (let i = 0; i < months.length; i++) {
          let month = months[i]
          this.xData.push(month+'月')
          if (series.firealarm.length>0) {
            for (let j = 0; j < series.firealarm.length; j++) {
              const el = series.firealarm[j];
              if (el.months &&  el != undefined) {
                if (el.months.substring(0,1) == '0') {
                  if (el.months.substring(1) == month) {
                    fireCounts.push(el.count)
                  }
                } else {
                  if (el.months == i) {
                    fireCounts.push(el.count)
                  }
                }
              }
            }
            if (fireCounts[i] == undefined) {
              fireCounts.push(0)
            }
          } else {
            fireCounts.push(0)
          }
          if (series.wateralarm.length>0) {
            for (let j = 0; j < series.wateralarm.length; j++) {
              const el = series.wateralarm[j];
              if (el.months && el.months != undefined) {
                if (el.months.substring(0,1) == '0') {
                  if (el.months.substring(1) == month) {
                    waterCounts.push(el.count)
                  }
                } else {
                  if (el.months == i) {
                    waterCounts.push(el.count)
                  }
                }
              }
            }
            if (waterCounts[i] == undefined) {
              waterCounts.push(0)
            }
          } else {
            waterCounts.push(0)
          }
          if (series.tempalarm.length>0) {
            for (let j = 0; j < series.tempalarm.length; j++) {
              const el = series.tempalarm[j];
              if (el.months &&  el.months != undefined) {
                if (el.months.substring(0,1) == '0') {
                  if (el.months.substring(1) == month) {
                    tempCounts.push(el.count)
                  }
                } else {
                  if (el.years == i) {
                    tempCounts.push(el.count)
                  }
                }
              }
            }
            if (tempCounts[i] == undefined) {
              tempCounts.push(0)
            }
          } else {
            tempCounts.push(0)
          }
          if (series.humidityalarm.length>0) {
            for (let j = 0; j <series.humidityalarm.length; j++) {
              const el = series.humidityalarm[j];
              if (el !== null && el !== undefined) {
                if (el.months.substring(0,1) == '0') {
                  if (el.months.substring(1) == month) {
                    humidityCounts.push(el.count)
                  }
                } else {
                  if (el.months == i) {
                    humidityCounts.push(el.count)
                  }
                }
              }
            }
            if (humidityCounts[i] == undefined) {
              humidityCounts.push(0)
            }
          } else {
            humidityCounts.push(0)
          }
        }
        this.seriesData.push(fireCounts,waterCounts,tempCounts,humidityCounts)
    },
    initFireChart() {
      var chartDom = document.getElementById("fireCharts");
      var myChart = echarts.init(chartDom);
      var option;
      var color = ["#FF3333", "#D7D7D7"];
      option = {
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
          top: "5%",
          left: "center",
        },
        graphic: {
          type: "text",
          top: "center",
          left: "center",
          style: {
            text: this.durationList.firesum + "%",
            fill: "#000",
            fontSize: "calc(3vh)",
            fontWeight: "bold",
          },
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "calc(3vh)",
                fontWeight: "bold",
              },
            },
            color: color,
            labelLine: {
              show: false,
            },
            data: [
              { value: this.durationList.firesum },
              { value: this.durationList.firesum >= 100 ? 0 : 100},
            ],
          },
        ],
      };

      option && myChart.setOption(option);
    },
    initwaterChart() {
      var chartDom = document.getElementById("waterCharts");
      var myChart = echarts.init(chartDom);
      var option;
      var color = ["#0a98c3", "#D7D7D7"];
      option = {
        tooltip: {
          show: false,
        },
        title: {
        },
        legend: {
          show: false,
          top: "5%",
          left: "center",
        },
        graphic: {
          type: "text",
          top: "center",
          left: "center",
          style: {
            text: this.durationList.watersum + "%",
            fill: "#000",
            fontSize: "calc(3vh)",
            fontWeight: "bold",
          },
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "calc(1.5vh)",
                fontWeight: "bold",
              },
            },
            color: color,
            labelLine: {
              show: false,
            },
            data: [
              { value: this.durationList.watersum },
              { value: this.durationList.watersum >= 100 ? 0 : 100},
            ],
          },
        ],
      };

      option && myChart.setOption(option);
    },
    initHumidityChart() {
      var chartDom = document.getElementById("humidityCharts");
      var myChart = echarts.init(chartDom);
      var option;
      var color = ["#f59110", "#D7D7D7"];
      option = {
        tooltip: {
          show: false,
        },
        title: {
        },
        legend: {
          show: false,
          top: "5%",
          left: "center",
        },
        graphic: {
          type: "text",
          top: "center",
          left: "center",
          style: {
            text: this.durationList.humiditysum + "%",
            fill: "#000",
            fontSize: "calc(3vh)",
            fontWeight: "bold",
          },
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "calc(3vh)",
                fontWeight: "bold",
              },
            },
            color: color,
            labelLine: {
              show: false,
            },
            data: [
              { value: this.durationList.humiditysum, name: "" },
              { value: this.durationList.humiditysum >= 100 ? 0 : 100, name: "" },
            ],
          },
        ],
      };

      option && myChart.setOption(option);
    },
    initTempChart() {
      var chartDom = document.getElementById("tempCharts");
      var myChart = echarts.init(chartDom);
      var option;
      var color = ["#80D0C7", "#D7D7D7"];
      option = {
        tooltip: {
          show: false,
        },
        title: {
        },
        legend: {
          show: false,
          top: "5%",
          left: "center",
        },
        graphic: {
          type: "text",
          top: "center",
          left: "center",
          style: {
            text: this.durationList.tempsum + "%",
            fill: "#000",
            fontSize: "calc(3vh)",
            fontWeight: "bold",
          },
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "calc(3vh)",
                fontWeight: "bold",
              },
            },
            color: color,
            labelLine: {
              show: false,
            },
            data: [
              { value: this.durationList.tempsum, name: "" },
              { value: this.durationList.tempsum >= 100 ? 0 : 100, name: "" },
            ],
          },
        ],
      };

      option && myChart.setOption(option);
    },
    initAlarmLineChart() {
      var chartDom = document.getElementById("alarmLineChart");
      var myChart = echarts.init(chartDom);
      var option;
      let xData = ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"]
      option = {
        title: {
          text: "设备报警次数曲线",
          left: "40%",
          textStyle: {
            color: "#000",
            fontSize: "calc(2.5vh)",
          },
        },
        tooltip: {
          trigger: "none",
        },

        legend: {
          x: "right", //可设定图例在左、右、居中
          padding: [0, 10, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          //   y:'center',
          data: ["火警", "浸水", "温度", "湿度"],
        },
        xAxis: {
          type: "category",
          data: this.xData,
          name: "", // x轴名称
          // x轴名称样式
          nameTextStyle: {
            fontWeight: 600,
            fontSize: 18,
          },
        },
        yAxis: {
          type: "value",
          name: "报警次数", // y轴名称
          // y轴名称样式
          nameTextStyle: {
            fontWeight: 400,
            fontSize: 14,
          },
        },
        series: [
          {
            name: "火警",
            data: this.seriesData[0],
            type: "line",
            smooth: true,
            lineStyle: {
              color: "#FF3333",
            },
            itemStyle : { normal: {label : {show: true}}}, 
          },
          {
            name: "浸水",
            data: this.seriesData[1],
            type: "line",
            smooth: true,
            lineStyle: {
              color: "#0a98c3",
            },
            itemStyle : { normal: {label : {show: true}}}, 
          },
          {
            name: "温度",
            data: this.seriesData[2],
            type: "line",
            smooth: true,
            lineStyle: {
              color: "#f59110",
            },
            itemStyle : { normal: {label : {show: true}}}, 
          },
          {
            name: "湿度",
            data: this.seriesData[3],
            type: "line",
            smooth: true,
            lineStyle: {
              color: "#80D0C7",
            },
            itemStyle : { normal: {label : {show: true}}}, 
          },
        ],
      };
      option && myChart.setOption(option);
      setTimeout(() => {
        window.onresize = function() {
          myChart.resize();
          echarts.init(document.getElementById("fireCharts")).resize()
          echarts.init(document.getElementById("waterCharts")).resize()
          echarts.init(document.getElementById("tempCharts")).resize()
          echarts.init(document.getElementById("humidityCharts")).resize()
        }
      },10)
    },
  },
};
</script>

<style lang="scss" scoped>

.selectClass{
  width: 9vw; 
  padding-right: 0.7vw;
}

.annual-container {
  background-color: #ffffff;
  // width: 80%;
  // height: 125vh;
  margin-left: 22%;
  margin-top: 4%;
  padding-bottom: 1%;
  margin-right: 6%;
  padding-right: 25px;

  .annual-img {
    // height: 60px; 
    // width: 60px; 
    // margin-top: 30px; 
    // margin-left: 30px;
    margin-top: -12px;
    float: left;
    position: absolute;
  }
  .annualClass {
    margin: 25px 0px 10px 60px;
    // height: 110vh;
    border: solid 2px #a5a5a5;
  }
  .annual-border {
    border-bottom: solid 2px #a5a5a5;
  }
  .annual-library{
    padding-left: 7vw; 
    padding-top: 15px;
    display: flex;
  }
  .annual-count{
    width: 18vw;
  }
  .annual-pie{
    width: 20vw;
    // margin-top: 3.5vh; 
    // height: 20vh;
  }
}


</style>
